import "./Netflix.css";
import React from "react";
import Logo from "./Logo";

const Page = ({ children = null, isLogin = false }: any) => (
  <div id="appMountPoint">
    {" "}
    <div className="login-wrapper hybrid-login-wrapper">
      <div className="login-wrapper-background">
        <img
          className="concord-img vlv-creative"
          src="https://assets.nflxext.com/ffe/siteui/vlv3/03fdc4bf-72f6-4926-83a7-a76e6a1a5591/9f09b85f-530e-4090-82c3-a60ea2b3177f/US-en-20211115-popsignuptwoweeks-perspective_alpha_website_small.jpg"
          srcSet="https://assets.nflxext.com/ffe/siteui/vlv3/03fdc4bf-72f6-4926-83a7-a76e6a1a5591/9f09b85f-530e-4090-82c3-a60ea2b3177f/US-en-20211115-popsignuptwoweeks-perspective_alpha_website_small.jpg 1000w, https://assets.nflxext.com/ffe/siteui/vlv3/03fdc4bf-72f6-4926-83a7-a76e6a1a5591/9f09b85f-530e-4090-82c3-a60ea2b3177f/US-en-20211115-popsignuptwoweeks-perspective_alpha_website_medium.jpg 1500w, https://assets.nflxext.com/ffe/siteui/vlv3/03fdc4bf-72f6-4926-83a7-a76e6a1a5591/9f09b85f-530e-4090-82c3-a60ea2b3177f/US-en-20211115-popsignuptwoweeks-perspective_alpha_website_large.jpg 1800w"
          alt=""
        />
      </div>
      <div className="nfHeader login-header signupBasicHeader">
        <a
          href="/"
          className="svg-nfLogo signupBasicHeader"
          data-uia="netflix-header-svg-logo"
        >
          <Logo />
          <span className="screen-reader-text">Netflix</span>
        </a>
      </div>
      <div className="profiles-gate-container">{children}</div>
    </div>
  </div>
);
export default Page;
