import React from "react";
const Input = ({
  type,
  name,
  id,
  value,
  label,
  onChange,
  placeholder,
}: any) => (
  <div className="nfInput nfEmailPhoneInput login-input login-input-email">
    <div className="nfEmailPhoneControls">
      <input
        type={type ?? "text"}
        id={id}
        className="nfTextField hasText"
        value={value}
        style={{
          backgroundImage: `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAUBJREFUOBGVVE2ORUAQLvIS4gwzEysHkHgnkMiEc4zEJXCMNwtWTmDh3UGcYoaFhZUFCzFVnu4wIaiE+vvq6+6qTgthGH6O4/jA7x1OiCAIPwj7CoLgSXDxSjEVzAt9k01CBKdWfsFf/2WNuEwc2YqigKZpK9glAlVVwTTNbQJZlnlCkiTAZnF/mePB2biRdhwHdF2HJEmgaRrwPA+qqoI4jle5/8XkXzrCFoHg+/5ICdpm13UTho7Q9/0WnsfwiL/ouHwHrJgQR8WEwVG+oXpMPaDAkdzvd7AsC8qyhCiKJjiRnCKwbRsMw9hcQ5zv9maSBeu6hjRNYRgGFuKaCNwjkjzPoSiK1d1gDDecQobOBwswzabD/D3Np7AHOIrvNpHmPI+Kc2RZBm3bcp8wuwSIot7QQ0PznoR6wYSK0Xb/AGVLcWwc7Ng3AAAAAElFTkSuQmCC")`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "scroll",
          backgroundSize: "16px 18px",
          backgroundPosition: "98% 50%",
          cursor: "auto",
        }}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
      />
      <label htmlFor={id} className="placeLabel">
        {label}
      </label>
    </div>
  </div>
);

export default Input;
