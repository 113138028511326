import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";

import "./App.css";
import Dashboard from "./Dashboard";
import Register from "./Register";
import Page from "./Page";

function App() {
  return (
    <Page>
      <Router>
        <Routes>
          <Route index element={<Login />} />
          <Route path={"/logout"} element={<Login />} />
          <Route path={"/register"} element={<Register />} />
          <Route path={"/dashboard"} element={<Dashboard />}>
            <Route path={":videoId"} element={<Dashboard />} />
          </Route>
        </Routes>
      </Router>
    </Page>
  );
}

export default App;
