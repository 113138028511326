import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "./firebase";
import "./Register.css";
import Input from "./components/Input";
function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  // @ts-ignore
  const [user, loading, error] = useAuthState(auth);
  const history = useNavigate();
  const register = () => {
    if (!name) alert("Please enter name");
    registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    if (user) history("/dashboard", { replace: true });
  }, [user, loading]);
  return (
    <div className="login-body">
      <div className="login-form">
        <div className="login-content login-form hybrid-login-form hybrid-login-form-signup">
          <div className="hybrid-login-form-main">
            <button
              className="btn login-button btn-submit btn-small register__btn register__google"
              onClick={signInWithGoogle}
            >
              Register with Google
            </button>
            <div>
              Already have an account? <Link to="/">Login</Link> now.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Register;
