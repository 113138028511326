import React from "react";
import "./WhoIsWatching.css";

const WhoIsWatching = ({ onClick }: any) => {
  return (
    <div className="profiles-gate-container">
      <div className="centered-div list-profiles-container">
        <div className="list-profiles">
          <h1 className="profile-gate-label">Who's watching?</h1>
          <ul className="choose-profile">
            <li className="profile">
              <div>
                <a
                  className="profile-link"
                  tabIndex={0}
                  href="#"
                  onClick={onClick("suxSBdwiXcQ")}
                  data-uia="action-select-profile+primary"
                >
                  <div className="avatar-wrapper">
                    <div
                      className="profile-icon"
                      data-profile-guid="CGCPTIDLTNFSXPOLEOR5RWLQ5Q"
                      style={{
                        backgroundImage:
                          "url(https://occ-0-444-448.1.nflxso.net/dnm/api/v6/K6hjPJd6cR6FpVELC5Pd6ovHRSk/AAAABb-r7rKXbMwfX-krjrLhvOJD6GdbVJvKxZ-3CDOvCThsnmEvZiaWI3sngIcGbwvUenXtOGpUJceNbfF2iLRAmEsHagoo.png?r=d6b)",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                  <span className="profile-name">Nina</span>
                </a>
                <div className="profile-children" />
              </div>
            </li>
            <li className="profile">
              <div>
                <a
                  className="profile-link"
                  tabIndex={0}
                  href="#"
                  data-uia="action-select-profile+secondary"
                  onClick={onClick("2wq3_D1I2L8")}
                >
                  <div className="avatar-wrapper">
                    <div
                      className="profile-icon"
                      data-profile-guid="3EKNSP7VKBDZHCXJGDWLNR6IP4"
                      style={{
                        backgroundImage:
                          "url(https://occ-0-444-448.1.nflxso.net/dnm/api/v6/K6hjPJd6cR6FpVELC5Pd6ovHRSk/AAAABelUIpf3YaFCJsmIxij79QjkiKVEFAzuz51DoBkEN0EBMCxNFyA-K7bhRMde7mA3N9sDKAc3Q9rm60fpZ_OhL_wphrvX.png?r=a70)",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                  <span className="profile-name">Naftali</span>
                </a>
                <div className="profile-children" />
              </div>
            </li>
            <li className="profile">
              <div>
                <a
                  className="profile-link"
                  tabIndex={0}
                  href="#"
                  data-uia="action-select-profile+kids"
                  onClick={onClick("REhCOkB6lck")}
                >
                  <div className="avatar-wrapper">
                    <div
                      className="profile-icon"
                      data-profile-guid="WWTXEV3X7VF35LVZ3STHHOHG2E"
                      style={{
                        backgroundImage: "url(./maurice.jpg)",
                        backgroundPosition: "center",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                  <span className="profile-name">Maurice</span>
                </a>
                <div className="profile-children" />
              </div>
            </li>
            <li className="profile">
              <div>
                <a
                  className="profile-link"
                  tabIndex={0}
                  href="#"
                  data-uia="action-select-profile+kids"
                  onClick={onClick("4TYE4A-9UmQ")}
                >
                  <div className="avatar-wrapper">
                    <div
                      className="profile-icon"
                      data-profile-guid="WWTXEV3X7VF35LVZ3STHHOHG2E"
                      style={{
                        backgroundImage: "url(./jordan.jpg)",
                        backgroundPosition: "center",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                  <span className="profile-name">Jordan</span>
                </a>
                <div className="profile-children" />
              </div>
            </li>
            <li className="profile">
              <div>
                <a
                  className="profile-link"
                  tabIndex={0}
                  href="#"
                  data-uia="action-select-profile+kids"
                  onClick={onClick("MO-SFjB96_c")}
                >
                  <div className="avatar-wrapper">
                    <div
                      className="profile-icon"
                      data-profile-guid="WWTXEV3X7VF35LVZ3STHHOHG2E"
                      style={{
                        backgroundImage: "url(./aaron.jpg)",
                        backgroundPosition: "center",
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                  <span className="profile-name">Aaron</span>
                </a>
                <div className="profile-children" />
              </div>
            </li>
          </ul>
        </div>
        <span data-uia="profile-button">
          <a
            aria-label="Manage Profiles"
            href="#"
            className="profile-button"
            onClick={onClick("kHWN_ifYfF8")}
          >
            Manage Profiles
          </a>
        </span>
      </div>
    </div>
  );
};

export default WhoIsWatching;
