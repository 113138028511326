import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  auth,
  signInWithEmailAndPassword,
  signInWithGoogle,
  logout,
} from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Input from "./components/Input";
import "./Login.css";
function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const location = useLocation();
  // @ts-ignore
  const [user, loading, error] = useAuthState(auth);
  const history = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (location.pathname.includes("logout")) {
      logout();
      history("/");
    }
    if (user) history("/dashboard");
  }, [user, loading, history]);
  return (
    <div className="login-body">
      <div className="login-form">
        <div className="login-content login-form hybrid-login-form hybrid-login-form-signup">
          <div className="hybrid-login-form-main">
            <h1 data-uia="login-page-title">Sign In</h1>
            <div className="login-form">
              <Input
                type="email"
                value={email}
                onChange={setEmail}
                placeholder="Email Address"
                label="Email Address"
              />
              <Input
                type="password"
                value={password}
                onChange={setPassword}
                placeholder="Password"
                label="Password"
              />
              <button
                className="btn login-button btn-submit btn-small login__google"
                onClick={signInWithGoogle}
              >
                Sign In
              </button>
            </div>
          </div>
          <div className="hybrid-login-form-other">
            <div className="login-signup-now" data-uia="login-signup-now">
              New to N2Flix? <Link to="/register">Sign up now</Link>.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
