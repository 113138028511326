import React from "react";
const Logo = () => (
  <svg
    viewBox="0 0 111 30"
    className="svg-icon svg-icon-netflix-logo"
    focusable="true"
  >
    <path
      style={{ fill: "#e50914", stroke: "#ff2a2a", strokeWidth: "0.1090123" }}
      d="M 0.0780267,14.948239 V 0.01355469 H 2.2450442 4.4120616 L 5.2264753,2.2755598 c 0.4479275,1.2441028 1.8151714,5.0697718 3.0383196,8.5014872 1.2231483,3.431715 2.2606981,6.240144 2.3056651,6.240953 0.04497,8.1e-4 0.08176,-3.824859 0.08176,-8.5014863 V 0.01355469 h 2.289259 2.289258 V 14.009503 28.005451 l -0.35429,0.06496 c -0.496931,0.09111 -4.168676,0.504367 -4.481271,0.504367 -0.243831,0 -0.4008167,-0.421026 -2.9164081,-7.821632 l -2.6587053,-7.821632 -0.027943,8.203175 -0.027943,8.203175 H 4.4926678 c -0.1493298,0 -1.1154174,0.122639 -2.1468617,0.272531 -1.0314442,0.149892 -1.96364911,0.272531 -2.07156632,0.272531 -0.18798778,0 -0.19621308,-0.626067 -0.19621308,-14.934685 z"
      id="path9109"
    />
    <path
      style={{ fill: "#e50914", stroke: "#ff2a2a", strokeWidth: "0.1090123" }}
      d="m 22.244162,27.605128 c 0.03081,-0.156196 0.268185,-1.432896 0.527499,-2.83711 l 0.471478,-2.553117 5.015494,-4.434265 c 2.758522,-2.438846 5.370254,-4.814139 5.803849,-5.278429 1.358907,-1.455104 1.941889,-3.0433897 1.685345,-4.5915827 C 35.220905,4.7307524 31.96795,3.5416881 27.707869,4.97175 26.800263,5.2764234 24.827614,6.1981348 23.86996,6.7649959 L 23.297646,7.1037641 V 4.1723535 1.2409428 l 1.171882,-0.33939499 c 1.458443,-0.42238727 2.254289,-0.60448352 3.906134,-0.89375576 1.685588,-0.29518154 4.940582,-0.40440599 6.437322,-0.21601052 4.100577,0.51614218 6.848265,2.57681967 7.686463,5.76459987 0.302355,1.1498939 0.302586,3.0253154 5.46e-4,4.1740552 -0.654869,2.4902704 -1.966584,4.0115114 -8.071079,9.3602694 -1.886166,1.65266 -3.350026,3.011974 -3.262328,3.02934 0.08749,0.01733 2.727343,-0.435988 5.866331,-1.007365 3.138987,-0.571377 5.719618,-1.0265 5.734733,-1.011385 0.01512,0.01512 -0.03805,0.345792 -0.118154,0.734836 -0.133429,0.648048 -0.38583,2.508392 -0.550769,4.059479 -0.05855,0.550604 -0.09552,0.626883 -0.304089,0.627328 -1.39663,0.003 -7.675394,0.530992 -10.430082,0.877117 -2.146849,0.269751 -6.21013,0.906385 -7.885829,1.23555 l -1.290584,0.253514 z"
      id="path9111"
    />
    <path
      style={{ fill: "#e50914", stroke: "#ff2a2a", strokeWidth: "0.1090123" }}
      d="M 53.276027,12.986018 V 0.01355469 h 6.486231 6.486232 V 2.3015328 4.5895107 l -4.224226,0.028533 -4.224227,0.028533 -0.02898,3.0250912 -0.02898,3.0250911 h 3.217587 3.217587 v 2.289258 2.289258 h -3.215862 -3.215863 v 5.341603 5.341602 h -2.234752 -2.234752 z"
      id="path9113"
    />
    <path
      style={{ fill: "#e50914", stroke: "#ff2a2a", strokeWidth: "0.1090123" }}
      d="M 78.239843,26.664 C 77.130642,26.58985 74.665602,26.465601 72.761975,26.387891 L 69.300834,26.246603 V 13.130079 0.01355469 h 2.289259 2.289258 V 10.953362 21.893167 l 1.389907,0.0715 c 0.764448,0.03933 2.395545,0.122721 3.624658,0.185322 1.229114,0.0626 2.394183,0.137925 2.589042,0.16739 l 0.35429,0.05357 v 2.229812 2.229812 L 81.046909,26.8147 C 80.612223,26.806 79.349043,26.73815 78.239843,26.664 Z"
      id="path9115"
    />
    <path
      style={{ fill: "#e50914", stroke: "#ff2a2a", strokeWidth: "0.1090123" }}
      d="m 89.086566,27.409503 c -0.17987,-0.02214 -0.940231,-0.07561 -1.689691,-0.118819 l -1.362653,-0.07856 -0.02768,-13.599283 -0.02768,-13.59928431 h 2.235177 2.235176 V 13.749104 27.484653 l -0.517809,-0.01745 c -0.284794,-0.0096 -0.664975,-0.03556 -0.844845,-0.0577 z"
      id="path9117"
    />
    <path
      style={{ fill: "#e50914", stroke: "#ff2a2a", strokeWidth: "0.1090123" }}
      d="m 108.00853,29.488648 c -1.22453,-0.203673 -2.23685,-0.38224 -2.24959,-0.396816 -0.0127,-0.01458 -0.77347,-1.977391 -1.6905,-4.361814 -0.91703,-2.384421 -1.69921,-4.297587 -1.73819,-4.251479 -0.039,0.04611 -0.82575,1.842761 -1.74839,3.992562 l -1.677522,3.908728 -0.493888,-0.06841 c -0.271638,-0.03763 -1.349346,-0.166532 -2.394907,-0.286457 -1.04556,-0.119926 -1.922229,-0.239257 -1.948152,-0.26518 -0.02592,-0.02593 1.283298,-3.078646 2.909382,-6.783827 1.626084,-3.705181 2.956516,-6.805617 2.956516,-6.889858 0,-0.08424 -1.20186,-3.250325 -2.670801,-7.0357407 -1.468941,-3.7854162 -2.670801,-6.91727569 -2.670801,-6.95968775 0,-0.04241207 1.099162,-0.07711286 2.442583,-0.07711286 h 2.442583 l 0.605467,1.55342521 c 0.333,0.8543839 1.01281,2.6003641 1.51067,3.8799561 0.49786,1.279592 0.94628,2.334286 0.99649,2.3437644 0.0502,0.00948 0.81711,-1.724238 1.70423,-3.8527031 l 1.61296,-3.86993646 2.51399,-0.0292513 2.51398,-0.02925129 -0.41897,1.01036195 c -0.23043,0.555699 -1.55293,3.7389695 -2.93888,7.0739342 -1.38596,3.3349646 -2.51992,6.1204796 -2.51992,6.1900316 0,0.06955 1.28252,3.521942 2.85004,7.671976 1.56752,4.150035 2.87321,7.631366 2.90154,7.73629 0.0695,0.257533 -0.10378,0.244938 -2.79992,-0.203504 z"
      id="path9119"
    />
  </svg>
);

export default Logo;
