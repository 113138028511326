import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useParams } from "react-router-dom";
import "./Dashboard.css";
import WhoIsWatching from "./components/WhoIsWatching";
import { auth, db, logout } from "./firebase";

function Dashboard() {
  // @ts-ignore
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const { videoId } = useParams();
  const [userAuthorized] = useState(user?.email.includes("lubin"));

  const clickBox = (videoId) => (e) => {
    e.preventDefault();
    navigate(`/dashboard/${videoId}`);
    return false;
  };

  useEffect(() => {
    if (loading) return;
    if (!user) {
      console.log("No user yet", loading, user, error)
      return navigate("/", { replace: true });
    }
    if (!userAuthorized) {
      logout();
      return navigate("/", { replace: true });
    }

    const fetchUserName = async () => {
      try {
        const query = await db
          .collection("users")
          .where("uid", "==", user?.uid)
          .get();
        const data = await query.docs[0].data();
        setName(data.name);
      } catch (err) {
        console.error(err);
        alert("An error occured while fetching user data");
      }
    };
    fetchUserName();
  }, [user, loading, history]);

  if (!userAuthorized) return null;

  return (
    <>
      {videoId ? (
        <iframe
          className="youtube-holder"
          src={`https://www.youtube-nocookie.com/embed/${videoId}?controls=0&autoplay=1`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <WhoIsWatching name={name} onClick={clickBox} />
      )}
    </>
  );
}
export default Dashboard;
